import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

/** A proxy identity assumed by service accounts using client credentials grant on behalf of some user or system */
export type AuthenticationEntity = IAuthenticationIdentity & ICreated & IDisplayImage & IDisplayName & IId & IUpdated & {
  __typename?: 'AuthenticationEntity';
  /** ISO date time string for the time this resource was created */
  createdAt?: Maybe<Scalars['String']>;
  /** Unique identifier for users that created this resource */
  createdBy?: Maybe<Scalars['String']>;
  /** A public url name safe to display in any HTML context */
  displayImageUrl?: Maybe<Scalars['String']>;
  /** A preformatted name safe to display in any HTML context */
  displayName: Scalars['String'];
  /** The primary id for this type. Typically a namespaced chain of methods, providers, and unique ids. */
  id: Scalars['ID'];
  /** The string will be in an IANA time zone format. https://www.iana.org/time-zones */
  timezone?: Maybe<Scalars['String']>;
  /** ISO date time string for the time this resource was created */
  updatedAt?: Maybe<Scalars['String']>;
  /** Unique identifier for users that created this resource */
  updatedBy?: Maybe<Scalars['String']>;
};

export enum AuthenticationRole {
  Anonymous = 'Anonymous',
  Authenticated = 'Authenticated'
}

/** An actual user with additional authentication details */
export type AuthenticationUser = IAuthenticationIdentity & ICreated & IDisplayImage & IDisplayName & IId & IUpdated & {
  __typename?: 'AuthenticationUser';
  /** True if the User is active. False if the User has been deactivated. Deactivated Users will not be able to login. */
  active: Scalars['Boolean'];
  /** ISO date time string for the time this resource was created */
  createdAt?: Maybe<Scalars['String']>;
  /** Unique identifier for users that created this resource */
  createdBy?: Maybe<Scalars['String']>;
  /** A public url name safe to display in any HTML context */
  displayImageUrl?: Maybe<Scalars['String']>;
  /** A preformatted name safe to display in any HTML context */
  displayName: Scalars['String'];
  /** Email addresses */
  email?: Maybe<Scalars['String']>;
  /** The primary id for this type. Typically a namespaced chain of methods, providers, and unique ids. */
  id: Scalars['ID'];
  /** The string will be in an IANA time zone format. https://www.iana.org/time-zones */
  timezone?: Maybe<Scalars['String']>;
  /** ISO date time string for the time this resource was created */
  updatedAt?: Maybe<Scalars['String']>;
  /** Unique identifier for users that created this resource */
  updatedBy?: Maybe<Scalars['String']>;
  /** If the account's email has been verified */
  verified: Scalars['Boolean'];
};

export type Carrier = {
  __typename?: 'Carrier';
  /** The particular name given to the carrier. */
  name: Scalars['String'];
};

export type Carriers = {
  __typename?: 'Carriers';
  list?: Maybe<Array<Maybe<Carrier>>>;
};

export type DocumentObject = IDisplayName & {
  __typename?: 'DocumentObject';
  /** User defined Carrier name. Suggestions are provided, but users may enter their own if required. */
  carrier?: Maybe<Scalars['String']>;
  /** A preformatted display name safe to display in HTML context */
  displayName?: Maybe<Scalars['String']>;
  docType?: Maybe<DocumentType>;
  /** The actual file name. */
  fileName?: Maybe<Scalars['String']>;
  isUploadComplete?: Maybe<Scalars['Boolean']>;
  /** One or more user selected values. Options are provided through linesOfBusiness APIs. */
  linesOfBusiness?: Maybe<Array<Scalars['String']>>;
};

export type DocumentObjectInput = {
  /** User defined Carrier name. Suggestions are provided, but users may enter their own if required. */
  carrier: Scalars['String'];
  /** A preformatted display name safe to display in HTML context */
  displayName: Scalars['String'];
  docType: DocumentType;
  /** The actual file name. */
  fileName: Scalars['String'];
  /** One or more user selected values. Options are provided through linesOfBusiness APIs. */
  linesOfBusiness: Array<Scalars['String']>;
};

/** The type of document i.e. Policy, Binder, Quote */
export enum DocumentType {
  Binder = 'Binder',
  ExpiringPolicy = 'ExpiringPolicy',
  Policy = 'Policy',
  Quote = 'Quote'
}

/** A base definition authentication actors */
export type IAuthenticationIdentity = {
  /** ISO date time string for the time this resource was created */
  createdAt?: Maybe<Scalars['String']>;
  /** Unique identifier for users that created this resource */
  createdBy?: Maybe<Scalars['String']>;
  /** A preformatted name safe to display in any HTML context */
  displayName: Scalars['String'];
  /** The primary id for this type. Typically a namespaced chain of methods, providers, and unique ids. */
  id: Scalars['ID'];
  /** The string will be in an IANA time zone format. https://www.iana.org/time-zones */
  timezone?: Maybe<Scalars['String']>;
  /** ISO date time string for the time this resource was created */
  updatedAt?: Maybe<Scalars['String']>;
  /** Unique identifier for users that created this resource */
  updatedBy?: Maybe<Scalars['String']>;
};

export type ICreated = {
  /** ISO date time string for the time this resource was created */
  createdAt?: Maybe<Scalars['String']>;
  /** Unique identifier for users that created this resource */
  createdBy?: Maybe<Scalars['String']>;
};

export type IDisplayImage = {
  /** A public url name safe to display in any HTML context */
  displayImageUrl?: Maybe<Scalars['String']>;
};

export type IDisplayName = {
  /** A preformatted display name safe to display in HTML context */
  displayName?: Maybe<Scalars['String']>;
};

export type IId = {
  /** The primary id for this type. Typically a UUID. */
  id?: Maybe<Scalars['ID']>;
};

export type IUpdated = {
  /** ISO date time string for the time this resource was created */
  updatedAt?: Maybe<Scalars['String']>;
  /** Unique identifier for users that created this resource */
  updatedBy?: Maybe<Scalars['String']>;
};

/** Lines of Business are defined by the existence of a set of and active revision TargetTermDefinitions. */
export type LineOfBusiness = {
  __typename?: 'LineOfBusiness';
  /** Is this targetTermDefinition active? */
  isActive?: Maybe<Scalars['Boolean']>;
  /** The particular name given to the line of business. */
  name: Scalars['String'];
  /** A UUID for the TargetTermDefinition */
  pk: Scalars['ID'];
  /** The revision number for the TargetTermDefinition */
  sk: Scalars['String'];
};

export type LinesOfBusiness = {
  __typename?: 'LinesOfBusiness';
  /** Get all lines of business. */
  list?: Maybe<Array<LineOfBusiness>>;
};

export type ManifestInput = {
  documents: Array<DocumentObjectInput>;
  jobId: Scalars['String'];
  submittedDateTime: Scalars['String'];
  submittedEmail: Scalars['String'];
  submittedUser: Scalars['String'];
};

export type ManifestJson = {
  __typename?: 'ManifestJson';
  documents: Array<DocumentObject>;
  jobId: Scalars['String'];
  submittedDateTime: Scalars['String'];
  submittedEmail: Scalars['String'];
  submittedUser: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  s3?: Maybe<S3Mutations>;
  submit?: Maybe<SubmitMutations>;
};

export type Query = {
  __typename?: 'Query';
  /** Get all carriers. */
  carriers?: Maybe<Carriers>;
  linesOfBusiness?: Maybe<LinesOfBusiness>;
  /** Returns an AuthenticationUser based on the actual or AuthenticationEntity proxy in request context */
  self?: Maybe<AuthenticationUser>;
  system: System;
};

export type S3Mutations = {
  __typename?: 'S3Mutations';
  /** Deletes a file from the S3 bucket */
  deleteFile?: Maybe<Scalars['JSON']>;
  /** Creates a signed url to put an object into an S3 bucket */
  getPutCommandSignedUrl: Scalars['String'];
};


export type S3MutationsDeleteFileArgs = {
  input: S3ObjectCommandInput;
};


export type S3MutationsGetPutCommandSignedUrlArgs = {
  input: S3ObjectCommandInput;
};

export type S3ObjectCommandInput = {
  key: Scalars['String'];
};

export type SubmitMutations = {
  __typename?: 'SubmitMutations';
  /** Moves files to the destination bucket, writes manifest.json to bucket to kick off processing */
  submitFiles: ManifestJson;
};


export type SubmitMutationsSubmitFilesArgs = {
  basePath: Scalars['String'];
  input: ManifestInput;
};

export type System = {
  __typename?: 'System';
  /** Provides a list of environmental variables */
  environment: Scalars['JSON'];
};

export type User = ICreated & IDisplayImage & IDisplayName & IId & IUpdated & {
  __typename?: 'User';
  /** True if the User is active. False if the User has been deactivated. Deactivated Users will not be able to login. */
  active: Scalars['Boolean'];
  /** ISO date time string for the time this resource was created */
  createdAt?: Maybe<Scalars['String']>;
  /** Unique identifier for users that created this resource */
  createdBy?: Maybe<Scalars['String']>;
  /** A public url name safe to display in any HTML context */
  displayImageUrl?: Maybe<Scalars['String']>;
  /** A preformatted name safe to display in any HTML context */
  displayName: Scalars['String'];
  /** Email addresses */
  email?: Maybe<Scalars['String']>;
  /** The primary id for this type. Typically a namespaced chain of methods, providers, and unique ids. */
  id: Scalars['ID'];
  /** The string will be in an IANA time zone format. https://www.iana.org/time-zones */
  timezone?: Maybe<Scalars['String']>;
  /** ISO date time string for the time this resource was created */
  updatedAt?: Maybe<Scalars['String']>;
  /** Unique identifier for users that created this resource */
  updatedBy?: Maybe<Scalars['String']>;
  /** If the account's email has been verified */
  verified: Scalars['Boolean'];
};

export type CarriersQueryVariables = Exact<{ [key: string]: never; }>;


export type CarriersQuery = { __typename?: 'Query', carriers?: { __typename?: 'Carriers', list?: Array<{ __typename?: 'Carrier', name: string } | null> | null } | null };

export type LinesOfBusinessQueryVariables = Exact<{ [key: string]: never; }>;


export type LinesOfBusinessQuery = { __typename?: 'Query', linesOfBusiness?: { __typename?: 'LinesOfBusiness', list?: Array<{ __typename?: 'LineOfBusiness', name: string }> | null } | null };

export type GetSignedPutUrlMutationVariables = Exact<{
  input: S3ObjectCommandInput;
}>;


export type GetSignedPutUrlMutation = { __typename?: 'Mutation', s3?: { __typename?: 'S3Mutations', getPutCommandSignedUrl: string } | null };

export type DeleteFileMutationVariables = Exact<{
  input: S3ObjectCommandInput;
}>;


export type DeleteFileMutation = { __typename?: 'Mutation', s3?: { __typename?: 'S3Mutations', deleteFile?: any | null } | null };

export type SubmitFilesMutationVariables = Exact<{
  input: ManifestInput;
  basePath: Scalars['String'];
}>;


export type SubmitFilesMutation = { __typename?: 'Mutation', submit?: { __typename?: 'SubmitMutations', submitFiles: { __typename?: 'ManifestJson', jobId: string } } | null };

export type SystemEnvironmentQueryVariables = Exact<{ [key: string]: never; }>;


export type SystemEnvironmentQuery = { __typename?: 'Query', system: { __typename?: 'System', environment: any } };

export type SelfQueryVariables = Exact<{ [key: string]: never; }>;


export type SelfQuery = { __typename?: 'Query', self?: { __typename?: 'AuthenticationUser', id: string, displayName: string, displayImageUrl?: string | null, email?: string | null } | null };


export const CarriersDocument = gql`
    query Carriers {
  carriers {
    list {
      name
    }
  }
}
    `;

/**
 * __useCarriersQuery__
 *
 * To run a query within a React component, call `useCarriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarriersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarriersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCarriersQuery(baseOptions?: Apollo.QueryHookOptions<CarriersQuery, CarriersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CarriersQuery, CarriersQueryVariables>(CarriersDocument, options);
      }
export function useCarriersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CarriersQuery, CarriersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CarriersQuery, CarriersQueryVariables>(CarriersDocument, options);
        }
export type CarriersQueryHookResult = ReturnType<typeof useCarriersQuery>;
export type CarriersLazyQueryHookResult = ReturnType<typeof useCarriersLazyQuery>;
export type CarriersQueryResult = Apollo.QueryResult<CarriersQuery, CarriersQueryVariables>;
export const LinesOfBusinessDocument = gql`
    query LinesOfBusiness {
  linesOfBusiness {
    list {
      name
    }
  }
}
    `;

/**
 * __useLinesOfBusinessQuery__
 *
 * To run a query within a React component, call `useLinesOfBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinesOfBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinesOfBusinessQuery({
 *   variables: {
 *   },
 * });
 */
export function useLinesOfBusinessQuery(baseOptions?: Apollo.QueryHookOptions<LinesOfBusinessQuery, LinesOfBusinessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LinesOfBusinessQuery, LinesOfBusinessQueryVariables>(LinesOfBusinessDocument, options);
      }
export function useLinesOfBusinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinesOfBusinessQuery, LinesOfBusinessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LinesOfBusinessQuery, LinesOfBusinessQueryVariables>(LinesOfBusinessDocument, options);
        }
export type LinesOfBusinessQueryHookResult = ReturnType<typeof useLinesOfBusinessQuery>;
export type LinesOfBusinessLazyQueryHookResult = ReturnType<typeof useLinesOfBusinessLazyQuery>;
export type LinesOfBusinessQueryResult = Apollo.QueryResult<LinesOfBusinessQuery, LinesOfBusinessQueryVariables>;
export const GetSignedPutUrlDocument = gql`
    mutation GetSignedPutUrl($input: S3ObjectCommandInput!) {
  s3 {
    getPutCommandSignedUrl(input: $input)
  }
}
    `;
export type GetSignedPutUrlMutationFn = Apollo.MutationFunction<GetSignedPutUrlMutation, GetSignedPutUrlMutationVariables>;

/**
 * __useGetSignedPutUrlMutation__
 *
 * To run a mutation, you first call `useGetSignedPutUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSignedPutUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSignedPutUrlMutation, { data, loading, error }] = useGetSignedPutUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSignedPutUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetSignedPutUrlMutation, GetSignedPutUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetSignedPutUrlMutation, GetSignedPutUrlMutationVariables>(GetSignedPutUrlDocument, options);
      }
export type GetSignedPutUrlMutationHookResult = ReturnType<typeof useGetSignedPutUrlMutation>;
export type GetSignedPutUrlMutationResult = Apollo.MutationResult<GetSignedPutUrlMutation>;
export type GetSignedPutUrlMutationOptions = Apollo.BaseMutationOptions<GetSignedPutUrlMutation, GetSignedPutUrlMutationVariables>;
export const DeleteFileDocument = gql`
    mutation DeleteFile($input: S3ObjectCommandInput!) {
  s3 {
    deleteFile(input: $input)
  }
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<DeleteFileMutation, DeleteFileMutationVariables>;
export const SubmitFilesDocument = gql`
    mutation SubmitFiles($input: ManifestInput!, $basePath: String!) {
  submit {
    submitFiles(input: $input, basePath: $basePath) {
      jobId
    }
  }
}
    `;
export type SubmitFilesMutationFn = Apollo.MutationFunction<SubmitFilesMutation, SubmitFilesMutationVariables>;

/**
 * __useSubmitFilesMutation__
 *
 * To run a mutation, you first call `useSubmitFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFilesMutation, { data, loading, error }] = useSubmitFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *      basePath: // value for 'basePath'
 *   },
 * });
 */
export function useSubmitFilesMutation(baseOptions?: Apollo.MutationHookOptions<SubmitFilesMutation, SubmitFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitFilesMutation, SubmitFilesMutationVariables>(SubmitFilesDocument, options);
      }
export type SubmitFilesMutationHookResult = ReturnType<typeof useSubmitFilesMutation>;
export type SubmitFilesMutationResult = Apollo.MutationResult<SubmitFilesMutation>;
export type SubmitFilesMutationOptions = Apollo.BaseMutationOptions<SubmitFilesMutation, SubmitFilesMutationVariables>;
export const SystemEnvironmentDocument = gql`
    query SystemEnvironment {
  system {
    environment
  }
}
    `;

/**
 * __useSystemEnvironmentQuery__
 *
 * To run a query within a React component, call `useSystemEnvironmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemEnvironmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemEnvironmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useSystemEnvironmentQuery(baseOptions?: Apollo.QueryHookOptions<SystemEnvironmentQuery, SystemEnvironmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemEnvironmentQuery, SystemEnvironmentQueryVariables>(SystemEnvironmentDocument, options);
      }
export function useSystemEnvironmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemEnvironmentQuery, SystemEnvironmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemEnvironmentQuery, SystemEnvironmentQueryVariables>(SystemEnvironmentDocument, options);
        }
export type SystemEnvironmentQueryHookResult = ReturnType<typeof useSystemEnvironmentQuery>;
export type SystemEnvironmentLazyQueryHookResult = ReturnType<typeof useSystemEnvironmentLazyQuery>;
export type SystemEnvironmentQueryResult = Apollo.QueryResult<SystemEnvironmentQuery, SystemEnvironmentQueryVariables>;
export const SelfDocument = gql`
    query Self {
  self {
    id
    displayName
    displayImageUrl
    email
  }
}
    `;

/**
 * __useSelfQuery__
 *
 * To run a query within a React component, call `useSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfQuery(baseOptions?: Apollo.QueryHookOptions<SelfQuery, SelfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
      }
export function useSelfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfQuery, SelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
        }
export type SelfQueryHookResult = ReturnType<typeof useSelfQuery>;
export type SelfLazyQueryHookResult = ReturnType<typeof useSelfLazyQuery>;
export type SelfQueryResult = Apollo.QueryResult<SelfQuery, SelfQueryVariables>;