import React from "react";
import { SelfQueryHookResult, useSelfQuery } from "../generated/types";
// import AuthService from "../services/auth.service";

export interface IUserContext {
  userQuery: SelfQueryHookResult;
}

const UserContext = React.createContext<IUserContext>({} as IUserContext);
UserContext.displayName = "UserContext";
export default UserContext;

// const authService = new AuthService();

interface Props {}
export const UserContextProvider = (({ children }) => {
  const userQuery = useSelfQuery({
    onCompleted: (data) => {
      if (!data.self?.id) {
        window.location.href = process.env.REACT_APP_LOGIN_URL!;
      }
    },
  });

  const context: IUserContext = {
    userQuery,
  };

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
}) as React.FunctionComponent<Props>;
