import Drawer from "@mui/material/Drawer/Drawer";
import Link from "@mui/material/Link";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import { AppBarTopProps } from "@torch-ai-internal/react-display-components/lib/components/App/AppBar/AppBarTop";
import { LogoutIcon } from "@torch-ai-internal/react-display-components/lib/themes/components/SemanticIcons/SemanticIcons";
import {
  ButtonBase,
  Grid,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";
import { useAppBarTopUserStyles } from "@torch-ai-internal/react-display-components/lib/components/App/AppBar/components/AppBarTopUser/AppBarTopUser";
import { makeStyles } from "tss-react/mui";
import Skeleton from "@mui/material/Skeleton";
import React from "react";
import AppBarUserAvatar from "@torch-ai-internal/react-display-components/lib/components/App/AppBarUserAvatar/AppBarUserAvatar";
import { imageSize } from "./AppBarBase";

const AppBarTopUser: AppBarTopProps["UserComponent"] = ({
  urls,
  user,
  isDrawerOpen,
  setIsDrawerOpen,
}) => {
  const { classes } = useAppBarTopUserStyles();
  const { classes: localClasses } = useStyles();
  const name = user?.name || "Guest";

  return (
    <>
      <div className={localClasses.container}>
        <Grid container direction={"row"} spacing={2} alignItems={"center"}>
          <Grid item className={localClasses.text}>
            <Typography
              color={"text.secondary"}
              variant={"overline"}
              className={localClasses.welcome}
              gutterBottom
              component={"div"}
            >
              Welcome
            </Typography>
            <Typography className={localClasses.name}>
              {user?.isLoading ? <Skeleton variant={"text"} /> : name}
            </Typography>
          </Grid>

          <Grid item>
            <ButtonBase
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={() => setIsDrawerOpen(true)}
              color={"inherit"}
            >
              <AppBarUserAvatar
                user={{ ...user, name }}
                sx={{ width: imageSize, height: imageSize }}
                variant={"rounded"}
              />
            </ButtonBase>
          </Grid>
        </Grid>
      </div>

      <Drawer
        anchor={"right"}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        className={classes.appBarDrawer}
      >
        <ErrorBoundary>
          <List>
            <ListSubheader>{name}</ListSubheader>
            {urls?.logout && (
              <Link href={urls?.logout} color={"inherit"} underline={"none"}>
                <ListItem>
                  <ListItemText primary="Logout" color="secondary" />
                  <ListItemIcon className={classes.logoutIcon}>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                </ListItem>
              </Link>
            )}
          </List>
        </ErrorBoundary>
      </Drawer>
    </>
  );
};
export default AppBarTopUser;

const useStyles = makeStyles({ name: "LocalAppBarTopUser" })((theme) => ({
  container: {
    margin: theme.spacing(1, 0),
  },
  text: {
    textAlign: "right",
  },
  welcome: {
    fontWeight: "500",
    fontSize: ".75rem",
    lineHeight: "1.1",
  },
  name: {
    fontWeight: "500",
    fontSize: "1.25rem",
    lineHeight: "1.15",
  },
}));
