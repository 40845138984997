import { AppRouteProps, AppRouteSubmit } from "../../AppRoutes";

export interface SubmitRouteParams {
  groupId: string;
}

export const SubmitRouteSubmission: AppRouteProps = {
  title: `Submission`,
  path: `${AppRouteSubmit.path}/:groupId`,
};
