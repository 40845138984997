import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import {
  AppRouteHome,
  AppRouteEnvironment,
  AppRouteUploadDocuments,
  AppRouteSubmit,
} from "../../../AppRoutes";
import PageLoading from "@torch-ai-internal/react-display-components/lib/components/Placeholders/PageLoading/PageLoading";
import { SubmitRouteSubmission } from "../../SubmitPage/SubmitRoutes";
const Home = React.lazy(() => import("../../Home"));
const UploadDocuments = React.lazy(
  () => import("../../ProcessFlows/Pages/UploadPage/UploadDocuments")
);
const SubmissionPage = React.lazy(
  () => import("../../SubmitPage/Page/SubmitPage")
);
const Environment = React.lazy(() => import("../../Environment/Index"));
const SubmitRouter = React.lazy(() => import("../../SubmitPage/SubmitRouter"));

const Router = (() => (
  <Suspense fallback={<PageLoading />}>
    <Routes>
      <Route {...AppRouteHome} element={<Home />} />
      <Route {...AppRouteEnvironment} element={<Environment />} />
      <Route {...AppRouteUploadDocuments} element={<SubmitRouter />}>
        <Route {...AppRouteUploadDocuments} element={<UploadDocuments />} />
      </Route>
      <Route {...AppRouteSubmit} element={<SubmitRouter />}>
        <Route
          path={`${SubmitRouteSubmission.path}/*`}
          element={<SubmissionPage />}
        />
      </Route>
    </Routes>
  </Suspense>
)) as React.FunctionComponent;
export default Router;
