import React, { useContext, useState } from "react";
import prismLight from "@torch-ai-internal/react-display-components/lib/themes/prismLight";
import AppBarBrandName from "@torch-ai-internal/react-display-components/lib/components/App/AppBarBrandName/AppBarBrandName";
import AppBarLogo from "@torch-ai-internal/react-display-components/lib/components/App/AppBarLogo/AppBarLogo";
import imageLogo from "../../../assets/flame-logo.svg";
import imageBrandName from "../../../assets/torch-ai-logo.svg";
import { deepmerge } from "@mui/utils";
import { createTheme } from "@torch-ai-internal/react-display-components/lib/themes/createTheme";
import { AppBarThemeProvider } from "@torch-ai-internal/react-display-components/lib/components/App/AppBar/AppBarBase";
import AppBarTop from "@torch-ai-internal/react-display-components/lib/components/App/AppBar/AppBarTop";
import { ThemeOptions } from "@mui/material";
import AppBarTopUser from "./AppBarTopUser";
import UserContext from "../../../contexts/UserContext";
import { makeStyles } from "tss-react/mui";
import { imageSize } from "./AppBarBase";

interface Props {}

const AppBar: React.FunctionComponent<Props> = () => {
  const { classes } = useStyles();
  const { userQuery } = useContext(UserContext);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  return (
    <AppBarThemeProvider theme={appBarTheme}>
      <div className={classes.container}>
        <AppBarTop
          appName={undefined}
          logo={<AppBarLogo src={imageLogo} />}
          brandName={<AppBarBrandName src={imageBrandName} />}
          isDrawerEnabled={false}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          urls={{
            logout: process.env.REACT_APP_LOGOUT_URL,
          }}
          user={{
            email: userQuery.data?.self?.email || undefined,
            isLoading: userQuery.loading,
            name: userQuery.data?.self?.displayName || "Guest",
            image: {
              src: userQuery.data?.self?.displayImageUrl || undefined,
            },
          }}
          UserComponent={AppBarTopUser}
        />
      </div>
    </AppBarThemeProvider>
  );
};

export default AppBar;

const brandingImageSize = Math.floor(imageSize * 0.66);
const useStyles = makeStyles({ name: "LocalAppBar" })((theme) => ({
  container: {
    "& [class*='AppBarTop-brandName']": {
      margin: theme.spacing(1, 0),
      maxWidth: "none",
      "& img": {
        height: `${brandingImageSize}px`,
      },
    },
    "& [class*='AppBarTop-logo']": {
      margin: theme.spacing(1, 0),
      "& img": {
        height: `${brandingImageSize}px`,
      },
    },
  },
}));

// Create a sub theme for the app bar which we'll mix with the current theme
const defaultTheme = createTheme({ palette: { mode: "dark" } });
const appBarThemeOptions = {
  palette: {
    mode: defaultTheme.palette.mode,
    action: defaultTheme.palette.action,
    background: {
      ...defaultTheme.palette.background,
      paper: "#232730",
    },
    text: defaultTheme.palette.text,
  },
} as ThemeOptions;

export const appBarTheme = createTheme(
  deepmerge(prismLight, appBarThemeOptions)
);

if (!process.env.REACT_APP_LOGOUT_URL) {
  throw new Error("process.env.REACT_APP_LOGOUT_URL is undefined");
}
