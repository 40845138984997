import React, { useState } from "react";
import { Theme } from "@torch-ai-internal/react-display-components/lib/themes/createTheme";
import { ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { CssBaseline } from "@mui/material";

export interface IThemesContext {
  theme: Theme;
  themes: Theme[];
  setTheme: (theme: Theme) => void;
}

const ThemesContext = React.createContext<IThemesContext>({} as IThemesContext);
ThemesContext.displayName = "ThemesContext";

export default ThemesContext;

interface Props {
  themes: Theme[];
  defaultTheme?: Theme;
}
export const ThemesContextProvider = (({ themes, defaultTheme, children }) => {
  if (!themes.length) {
    throw new Error("At least one theme must be provided");
  }

  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [theme, setTheme] = useState<Theme>(defaultTheme || themes[0]);
  const context: IThemesContext = {
    themes,
    theme,
    setTheme,
  };

  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });

  return (
    <ThemesContext.Provider value={context}>
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </CacheProvider>
    </ThemesContext.Provider>
  );
}) as React.FunctionComponent<Props>;
