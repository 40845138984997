import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";

if (!process.env.REACT_APP_GRAPHQL_URL) {
  throw new Error("process.env.REACT_APP_GRAPHQL_URL is undefined");
}
if (!process.env.REACT_APP_LOGIN_URL) {
  throw new Error("process.env.REACT_APP_LOGIN_URL is undefined");
}

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.REACT_APP_GRAPHQL_URL,
  credentials: "include",
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      switch (err.extensions.code) {
        case "UNAUTHENTICATED":
          // Path and params
          const currentState = window.location.href.replace(
            window.location.origin,
            ""
          );
          const loginUrl = [
            process.env.REACT_APP_LOGIN_URL,
            new URLSearchParams({
              state: currentState,
            }).toString(),
          ].join("?");
          window.location.href = loginUrl;
      }
    });
  }
});

const retryLink = new RetryLink();

// Cache implementation
const cache = new InMemoryCache({});

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: from([errorLink, retryLink, httpLink]),
  cache,
});
