import { RouteProps } from "react-router-dom";

export interface AppRouteProps extends RouteProps {
  title: string;
  // A set of properties for making a mappable list
  url?: string;
}

export const AppRouteHome: AppRouteProps = {
  title: `Home`,
  path: `/`,
};
export const AppRouteUploadDocuments: AppRouteProps = {
  title: `Upload your documents`,
  path: `/upload-documents`,
};

export const AppRouteSubmit: AppRouteProps = {
  title: `Submit`,
  path: `/submit`,
};
export const AppRouteLogout: AppRouteProps = {
  title: `Logout`,
  path: `/logout`,
};

export const AppRouteEnvironment: AppRouteProps = {
  title: `Environment`,
  path: `/environment`,
};
