import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { ThemesContextProvider } from "../../../contexts/ThemesContext";
import { AppContextProvider } from "../../../contexts/AppContext";
import { UserContextProvider } from "../../../contexts/UserContext";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "../../../services/apollo";
import prismLight from "@torch-ai-internal/react-display-components/lib/themes/prismLight";

const Providers = (({ children }) => (
  <Router>
    {/*This needs to be kept inside <Router /> to control redirection for authentication*/}
    <ApolloProvider client={apolloClient}>
      <AppContextProvider>
        <ThemesContextProvider themes={[prismLight]}>
          <UserContextProvider>
            <SnackbarProvider maxSnack={3}>
              <HelmetProvider>{children}</HelmetProvider>
            </SnackbarProvider>
          </UserContextProvider>
        </ThemesContextProvider>
      </AppContextProvider>
    </ApolloProvider>
  </Router>
)) as React.FunctionComponent;
export default Providers;
