import React from "react";
import { Helmet } from "react-helmet-async";
import Providers from "./components/Providers";
import { getMetaTitle } from "@torch-ai-internal/react-display-components/lib/utils/meta";
import { ErrorBoundary } from "@torch-ai-internal/react-display-components/lib/components/Feedback/ErrorBoundary/ErrorBoundary";
import Router from "./components/Router";
import { appName } from "../../constants";
import { makeStyles } from "tss-react/mui";
import AppBar from "./components/AppBar";

const App: React.FunctionComponent = () => {
  const { classes } = useStyles();

  return (
    <Providers>
      <Helmet>
        <title>
          {getMetaTitle(undefined, [appName, "Torch AI"].join(" - "))}
        </title>
      </Helmet>

      <div className={classes.root}>
        <AppBar />

        <main className={classes.main}>
          <div className={classes.content}>
            <ErrorBoundary>
              <Router />
            </ErrorBoundary>
          </div>
        </main>
      </div>
    </Providers>
  );
};
export default App;

const useStyles = makeStyles({ name: "App" })(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    height: "100vh",
    width: "100%",
  },
  main: {
    flexWrap: "nowrap",
    flexGrow: 1,
    overflow: "hidden",
  },
  content: {
    width: "100%",
    height: "100%",
  },
}));
